<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      quotas: null,
      total: 0,
      statData: null,
    };
  },
  methods: {
    getList() {
      api.get("quotas").then((response) => {
        if (response.data.status == "success") {
          this.quotas = response.data.list;
          this.total = response.data.total;

          this.statData = [
            {
              icon: "total",
              title: "Total",
              value: response.data.total.toString(),
            },
          ];
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Minhas Cotas") }}</h4>
        </div>
      </div>
    </div>

    <div class="row" v-if="this.total > 0">
      <div class="col-12">
        <a href="https://t.me/Ashowoficial" target="_blank">
          <div class="d-block bg-warning rounded text-default p-3 mb-3 d-flex">
            <div class="align-self-center">
              <i class="bx bxl-telegram text-default h1 m-0"></i>
            </div>
            <div class="align-self-center ml-3">
              <h5 class="m-0 text-default pr-3">
                Clique aqui para participar do nosso grupo oficial no telegram
              </h5>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Data</th>
                    <th>Cotas</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!quotas">
                    <td colspan="100%" class="text-center">
                      <b-spinner
                        small
                        class="ml-2 align-middle"
                        variant="default"
                        role="status"
                      ></b-spinner>
                    </td>
                  </tr>
                  <tr v-else-if="quotas && quotas.length == 0">
                    <td colspan="100%" class="text-center">
                      Nenhum registro encontrado.
                    </td>
                  </tr>
                  <template v-else>
                    <tr v-for="token in quotas" :key="token.index">
                      <td>{{ token.id.split("-")[0] }}</td>
                      <td>{{ token.date }}</td>
                      <td>{{ token.total }}</td>
                      <td>
                        <span
                          v-if="token.status === 'active'"
                          class="badge badge-soft-success font-size-12"
                          >ATIVO</span
                        >
                        <span
                          v-else-if="token.status === 'inactive'"
                          class="badge badge-soft-warning font-size-12"
                          >INATIVO</span
                        >
                        <span
                          v-else-if="token.status === 'deleted'"
                          class="badge badge-soft-danger font-size-12"
                          >EXCLUÍDO</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
